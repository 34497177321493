import React from 'react';
import './PendingPage.css';
import { useEffect, useState } from 'react';
import { postRawData } from '../../restfulservices/httpHandler';
import { useHistory } from "react-router-dom";

const PendingPage = () => {
    const history = useHistory();
    const [userId, setUserId] = useState(null);
    const [loading, setLoading] = useState(true);
    const fetchData = async (userId) => {
        try {
            const response = await postRawData(
                `v1/stripe/update_stripe_unbording_status`,
                { userId: userId }

            );
            if (response?.data?.status) {
                history.push("/success-page");
            } else {
                console.log('Unsuccessful status.');
            }
            console.log('User data:', response.data);
        } catch (error) {
            console.error('Error fetching user data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const userIdFromUrl = queryParams.get('userId');
        console.log(userIdFromUrl);

        setUserId(userIdFromUrl);

        if (userIdFromUrl) {
            fetchData(userIdFromUrl);
        }
    }, []);

    return (
        <div className="pending-container">
            <div className="pending-box">
                <h1>Processing...</h1>
                <p>Your request is being processed. Please wait.</p>
            </div>
        </div>
    );
};

export default PendingPage;
